.edit-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.edit-form{
    display: flex;
    flex-direction: column;
    margin-right: 0rem;
    margin-bottom: 0.5rem;
}

.edit-image{
    width: 5rem;
    object-fit: cover;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.edit-image-container{
    display: flex;
    flex-wrap: wrap;
}

.edit-images{
    display: flex;
    flex-direction: column;
    margin-left: 0rem;
}

.edit-input{
    width: 90vw;
    margin-bottom: 0.5rem;
}

.delete-image-button{
    background-color: transparent !important;
    border: 1px solid red;
    height: 2rem;
}

.delete-image-button:hover{
    background-color: lightcoral !important;
    border: 2px solid red;
    height: 2rem;
}

.delete-image-button:hover::after{
    content: "Verwijder Foto?";
}

@media screen and (min-width: 1000px) {
    .edit-container{
        width: 30vw;
    }
    .edit-input{
        width: 25vw;
    }
}