.admin{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.login-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.admin-left-menu{
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    border: 1px solid darkgrey;
    padding: 0.5rem;
}

.admin-right-menu{
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    border: 1px solid darkgrey;
    padding: 0.5rem;
}

.admin-form{
    display: flex;
    flex-direction: column;
}
.button-neutral{
    width: 7rem;
    height: 2rem;
    margin: 0.5rem;
    border-radius: 0.5rem;
}
.admin-submit-button {
  margin-top: 1rem;
  background-color: initial;
  background-image: linear-gradient(-180deg, #00D775, #00BD68);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: Inter,-apple-system,system-ui,Roboto,"Helvetica Neue",Arial,sans-serif;
  height: 44px;
  line-height: 44px;
  outline: 0;
  overflow: hidden;
  padding: 0 20px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  white-space: nowrap;
  width: 10rem;
  z-index: 9;
  border: 0;
}

.admin-submit-button:hover {
  background: #00bd68;
}

.admin-submit-button_small{
    width: 40%;
}

.admin-overview-item{
    margin-top: 0.5rem;
    background-color: #589aab50;
    border: 2px dashed darkred;
    padding: 0.5rem;
}

.delete-button{
    background-color: #e1484899;
    background-image: none;
    color: black;
    font-size: 0.8em;
    font-weight: bold;
    width: 6rem;
    border-radius: 1rem;
}

.admin-header{
    background-color: #4390ee70;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 1rem;
}
.admin-overview-menu{
    display: flex;
    flex-direction: column;
    width: 100vw;
    justify-content: center;
    align-items: center;
}
.admin-overview-text-container{
    width: 5rem;
    display: flex;
    flex-direction: column;
}

.admin-overview-description{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.text-bold-small{
    font-size: 0.8rem;
    font-weight: bold;
}

.admin-overview-item{
    width: 96vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.admin-overview-description{
    font-size: 0.8rem;
}
.admin-overview-image{
    width: 5rem;
    height: 5rem;
    object-fit: contain;
}
.fail-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #ff595980;
    height: 4rem;
    font-size: 1.2em;
    font-weight: bold;
    border-radius: 1em;
}

.success-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #2aad2c80;
    height: 4rem;
    font-size: 1.2em;
    font-weight: bold;
    border-radius: 1em;
}

.loading-box{
    height: 20rem;
    width: 100%;
    background-color: rgb(220, 213, 213);
    display: flex;
    justify-content: center;
    align-items: center;
}
@media screen and (min-width:1100px) {
    .admin{
        flex-direction: row;
        justify-content: space-around;
    }
    .admin-left-menu{
        width: 25vw;
    }
    .admin-right-menu{
        width: 35vw;
    }
    .admin-overview-menu{
        width: 30vw;
    }
    .admin-overview-item{
        width: 30vw;
    }
}
