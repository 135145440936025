@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');

.Home{
    display: flex;
    flex-direction: column;
    align-items: center;
}

a{
    color: black;
    text-decoration: none;
  }

  .home-logo_large{
      display: none !important;
      margin: -3rem 0rem -4rem 3rem;
      width: 20rem;
  }

  .home-header{
    font-family: 'Titillium Web', sans-serif;
    font-weight: 700;
    font-size: 4em;
    margin-left: 0rem;
    display: none;
    width:5em;
    background: linear-gradient(
    to bottom,
    transparent 50%,
    #70877F80 50%
    );
}

.home-subheader{
    font-family: 'Titillium Web', sans-serif;
    font-weight:600;
    margin-bottom: 2rem;
    font-size: 1.5em;
    display: none;
}

.home-item-container{
    position: relative;
    text-align: center;
}

.home-social-container{
    display: none;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 5em;
    margin-bottom: 2em;
}

.home-social-icon{
    color: black;
    margin-left: 1em;
    transform: scale(2.5);
    margin-right: 2em;
    filter: opacity(0.7);
}
.home-social-icon:hover{
    transform: scale(2.8);
    filter: opacity(1);
}
.home_image{
    position: relative;
    text-align: center;
    width: 80vw;
    height: 20em;
    object-fit: cover;
    margin-bottom: 2em;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transition: ease-in-out;
    transition-duration: 200ms;
    filter: saturate(0.6);
}

.home_image:hover + .home_image_text{
    background-color: orange;
    transition: ease-in-out;
    transition-duration: 200ms;
}

.home_image:hover{
    transform: scale(1.025);
    transition: ease-in-out;
    transition-duration: 200ms;
    filter: saturate(0.9);
}

.home_image_text{
    position: absolute;
    bottom: 1.75em;
    right: 2.5em;
    color: white;
    background-color: #70877F80;
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-family: 'Titillium Web', sans-serif;
    font-size: 1.5em;
}

@media screen and (min-width:750px) {
    .home_image_text{
        bottom: 1.5em;
        right: 4em;
        font-family: 'Titillium Web', sans-serif;
        font-size: 2em;
    }

    .home_image{
        margin-top: 1rem;
        width: 40rem;
        height: 30rem;
        object-fit: cover;
    }

    .home_image_wide{   
        margin-top: 0;
        width: 40rem;
        height: 20em;
    }
}

@media screen and (min-width: 1000px) {
    .home-header{
        display: block;
        font-size: 2.5rem;
    }
    .home-subheader{
        display: inline;
        font-size: 1rem;
    }
    .home_image{
        margin-top: 2rem;
        width: 40vw;
        max-height: 35em;
    }

    .home-logo_large{
        display: inline !important;
    }

    .home_image_wide{
        margin-top: 0;
        width: 50vw;
        height: 20em;
    }

    .home-social-container{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-right: 5em;
        margin-bottom: 2em;
    }
}


@media screen and (min-width: 2200px) {
    .home-header{
        margin-top: 3rem;
        display: block;
        font-size: 6rem;
    }
    .home-subheader{
        display: inline;
        font-size: 1.5rem;
    }
    .home-item-container_wide{
        margin-top: 10em;
    }
    .home_image{
        margin-top: 2rem;
        width: 40em;
        max-height: 40rem;
    }

    .home_image_wide{
        margin-left: 5em;
        margin-top: 0;
        width: 50em;
        height: 20em;
    }
    .home-logo_large{
        margin: 1rem 0 0 0;
        display: inline !important;
        width: 25rem;
    }
    .home-social-container{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-right: 5em;
        margin-bottom: 2em;
    }

    .home_image_text{
        bottom: 1.5em;
        right: 1em;
        font-family: 'Titillium Web', sans-serif;
        font-size: 2em;
    }
}