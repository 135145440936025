@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');

.gallery{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gallery-photo{
    width: 80vw;
}

.gallery-single-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.gallery-single-photo{
    width: 45vw;
    height: 55vw;
    margin-bottom: 0.5rem;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.gallery-multiple-container-main{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.gallery-multiple-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 95vw;
    max-height: 50rem;
    justify-content: center;
    align-items: center;
    border: 2px dashed #70877F80;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    overflow: hidden;
}

.gallery-multi-main-photo{
    width: 90vw;
    max-width: 30rem;
    margin-right: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.gallery-multi-secondary-photo{
    width: 6rem;
    height: 5.5rem;
    margin-right: 0.5rem;
    object-fit: cover;
    margin-bottom: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.image-set-secondary-container{
    margin-top: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.image-description-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.image-title{
    font-family: 'Titillium Web', sans-serif;
    font-weight: 600;
    margin: 0 0 0.5rem 0;
    width: 20rem;
    color: #21666b;
}

.image-description{
    font-family: 'Titillium Web', sans-serif;
    margin: 0rem 0 0 0.5rem;
    font-size: 0.9em;
    color: #21666b;
}

@media screen and (min-width:760px) {
    .gallery-single-container{
        justify-content: center;
    }

    .gallery-single-photo{
        width: 10rem;
        height: 15rem;
        margin-right: 0.5rem;
    }

    .image-description-container{
        display: none !important;
    }

    .image-description{
        display: none !important;
    }

    .gallery-multiple-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start !important;
        width: 45vw;
        height: 35rem;
        margin-top: 0.5rem;
        margin-right: 1rem;
    }

    .image-set-secondary-container{
        margin-top: 0.5rem;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start !important;
    }

    .image-description-container{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }

    .gallery-multi-main-photo{
        width: 20rem;
        height: 22.5rem;
        object-fit: cover;
    }

    .gallery-multi-secondary-photo{
        width: 4rem;
        height: 4rem;
        margin-right: 0.5rem;
    }
}

@media screen and (min-width:900px) {

    .gallery-single-container{
        justify-content: center;
    }

    .gallery-multi-main-photo{
        width: 98%;
        height: 28rem;
        object-fit: cover;
    }

    .gallery-multi-secondary-photo{
        width: 5rem;
        height: 8rem;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .gallery-single-photo{
        width: 10rem;
        height: 15rem;
        margin-right: 0.5rem;
    }

    .gallery-multiple-container{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 30rem;
        height: 45rem;
        margin-top: 0.5rem;
        margin-right: 1rem;
    }

    .image-set-secondary-container{
        margin-top: 0.5rem;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .image-description-container{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }
}

@media screen and (min-width:2000px) {
    .gallery-multiple-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 30rem;
        height: 35rem;
        margin-top: 0.5rem;
        margin-right: 1rem;
    }

    .image-set-secondary-container{
        margin-top: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .gallery-multi-secondary-photo{
        width: 6.5rem;
        height: 8rem;
        margin-right: 0.5rem;
    }
}