@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');

.footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    height: 9em;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.footer-social{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footer-text-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.footer-text{
    font-family: 'Titillium Web', sans-serif;
    font-weight: 300;
    margin-left: 1em;
    font-size: 0.85em;
    color: black;
}

.footer-text_bold{
    font-weight: 600;
    margin-left: 0;
}

.footer-social-icon{
    margin-left: 1.5em;
    margin-right: 1.5em;
    margin-bottom: 1em;
    filter: opacity(0.7);
    transform: scale(1.6);
    color: black;
}
.footer-social-icon:hover{
    transform: scale(1.8);
    filter: opacity(1);
}