@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');

.navBar{
    margin-bottom: 0em;
}

.main-header{
    display: none !important;
    font-family: 'Titillium Web', sans-serif;
    font-weight: 700;
    margin-left: 0em;
    font-size: 1.5em;
}
.nav-brand-container{
    display: flex;
}
.nav-logo{
    margin: -1.25rem 0rem 0 1rem;
    width: 7rem;
    object-fit: contain;
}

.nav-link{
    margin-left: 2em;
    font-family: 'Titillium Web', sans-serif;
    font-size: 1.1em;
    font-weight: 700;
}

.navbar button{
    margin-right: 1rem;
}