@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');

.Contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact--form{
    display: flex;
    flex-direction: column;
}
.email-link{
    text-decoration: underline;
    color: black;
}
.contact--form__label{
    margin: 0;
}

.contact--form__input{
    border-radius: 0.5em;
    border: 1px solid darkgreen;
}

.contact-sheet{
    margin-top: 2em;
    padding-top: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed #70877F80;
    border-radius: 0.1em;
    width: 90vw;
    padding-bottom: 5em;
    padding-left: 1em;
    padding-right: 1em;
}

.contact-text{
    text-align: center;
    font-family: 'Titillium Web', sans-serif;
}

.contact-header{
    font-size: 2rem;
    margin-bottom: 1rem;
    font-weight: 600;
    color: #21666b;
    font-family: 'Titillium Web', sans-serif;
    font-style: italic;
}

.contact-text-bold{
    font-family: 'Titillium Web', sans-serif;
    font-weight: bolder;
}

@media screen and (min-width: 1100px) {
    .contact-sheet{
        width: 40vw;
    }
}